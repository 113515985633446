@use '~@woodpecker-web-shared/typography';

@font-face {
  font-display: swap;
  font-family: "HeptaSlabMedium";
  font-weight: 500;
  src: url("./assets/fonts/HeptaSlabMedium.ttf") format("truetype");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  padding: 0;
  font-family: 'PoppinsRegular', sans-serif;
  font-weight: 400;
  color: #0B0204;
  min-height: calc(100 * var(--vh, 1vh));
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  box-sizing: border-box;
  border: none;
  display: inline-flex;
  appearance: none;
  text-decoration: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

// PWA specific.
@media (display-mode: standalone), (display-mode: minimal-ui) {
  body {
    padding: env(safe-area-inset-top) 
             env(safe-area-inset-right) 
             env(safe-area-inset-bottom) 
             env(safe-area-inset-left) !important;
  }
}